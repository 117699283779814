<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryParams" :model="queryParams" label-width="100px" :inline="true">
        <el-form-item label="所屬單位">
            <el-select v-model="queryParams.organization" filterable placeholder="請選擇" clearable="">
              <el-option
                v-for="item in organizations"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="人員">
                       <el-select
    v-model="queryParams.accounts"
    multiple
    filterable
    remote
    reserve-keyword
    clearable
    placeholder="請輸入關鍵字"
    :remote-method="remoteOptionQuery"
    :loading="accountOptionLoading">
    <el-option
      v-for="item in accounts"
      :key="item.id"
      :label="item.name"
      :value="item.id">
      <span style="float: left">{{ item.name }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.organization.name }}</span>
    </el-option>
  </el-select>
         
        </el-form-item>
                <el-form-item label="音檔">
                       <el-select
    v-model="queryParams.audios"
    multiple
    filterable
    remote
    reserve-keyword
    clearable
    placeholder="請輸入關鍵字"
    :remote-method="remoteAudioOptionQuery"
    :loading="audioOptionLoading">
    <el-option
      v-for="item in audios"
      :key="item.id"
      :label="item.title"
      :value="item.id">
       <span style="float: left">{{ item.title }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.audio_no }}</span>
    </el-option>
  </el-select>
         
        </el-form-item>
         <el-form-item>
          <el-button type="primary" @click="currentPage=1;query(pageSize,0,sort,order, queryParams)">查詢</el-button>
          <el-button type="primary" @click="batchEditAudio" v-if="$store.state.auth.role === 'admin'">批次編輯</el-button>
          <el-button type="danger" @click="batchDelete" v-if="$store.state.auth.role === 'admin'">批次刪除</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
            :data="audioAccounts"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
            @sort-change = "sortChange"
            @selection-change="handleSelectionChange"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
            <el-table-column
            fixed
            type="selection"
            width="45">
          </el-table-column>
          organization
                      <el-table-column
                label="單位名稱"
                prop="account.organization.name"
                width="120"
            >
            </el-table-column>
            <el-table-column
                label="人員名稱"
                prop="account.name"
                width="120"
                sortable="custom"
            >
            </el-table-column>
             <el-table-column
                label="分類"
                 prop="category"
                 :formatter="categoryFormatter"
            >
            <!--
            <template slot-scope="scope">
              <keep-alive>
                {{ categoryFormatter(scope.row.category.id)}}
              </keep-alive>
            </template>
            -->
            </el-table-column>
            <el-table-column
                label="開示日期"
                prop="audio.audio_no"
                width="120"
                sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="開示主題"
                prop="audio.title"
                sortable="custom"
         
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="expiration_date"
                :formatter="dateFormatter"
                width="120px"
                sortable="custom"
            >
            </el-table-column>
    
            <el-table-column
              fixed="right"
              label="操作"
              width="150" v-if="$store.state.auth.role === 'admin'">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteItem(scope.row.audio_id,scope.row.account_id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
     <el-dialog title="批次修改" :visible.sync="dialogBatchEditFormVisible" width="1100px" v-loading="loading" element-loading-text="作業中">
      <el-form :model="batchForm" ref="batchForm" :rules="batchRules">
        <el-form-item label="失效日期" :label-width="formLabelWidth" prop="expiration_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="batchForm.expiration_date"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBatchEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBatch">確 定</el-button>
      </div>
     </el-dialog>
   
  
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import API from '@/api/'
import moment from "moment-timezone";
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'AssignList',
  created () {
    this.$store.dispatch('account/clear', {}, {
        root: true
    });
    this.$store.dispatch('audio/clear', {}, {
        root: true
    });
    this.$store.dispatch('audioAccount/list', {max: this.pageSize, offset: 0, sort: this.sort, order: this.order}, {
        root: true
    });
    this.$store.dispatch('organization/list', {max: 1000, offset: 0}, {
        root: true
    });
  },
  data() {
        return {
          audioOptionLoading: false,
          accountOptionLoading: false,
          pageSize:10,
          currentPage:0,
          loading: false,
          multipleSelection: [],
          batchForm: {
            expiration_date: null
          },
          queryParams: {
            accounts:[],
            audios:[],
            organization: ''
          },
          upload:{
            headers:{Authorization:""},
            multiple: false,
            limit: 1,
            autoUpload: false,
            data:{audio_id:0}
          },
          props: {
            value: 'id',
            label: 'name',
            checkStrictly: true
          },
          dialogBatchEditFormVisible: false,
          dialogFormVisible: false,
          dialogAssignVisible: false,
          formLabelWidth: '120px',
          rules: {
            audio_no: [
              { required: true, message: '請輸開示日期', trigger: 'blur' },
            ],
            title: [
              { required: true, message: '請輸開示主題', trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
            category: [
              { required: true, message: '請選擇分類', trigger: 'blur' },
            ]
          },
          batchRules: {
          },
          
        }
  },
  methods:{
      remoteAudioOptionQuery(query) {
        if (query !== '') {
          this.audioOptionLoading = true;
          this.$store.dispatch('audio/list', {sort: 'id', order: 'ascending', qstr:query, max: 100000, offset: 0}, {
          root: true
          }).then(()=>{
               this.audioOptionLoading = false;
          })
        } else {
          this.$store.dispatch('audio/clear', {} , {root: true})
        }
      },
    remoteOptionQuery(query) {
        if (query !== '') {
            this.accountOptionLoading = true;
          this.$store.dispatch('account/list', {sort: 'no', order: 'ascending', qstr:query, organization: this.queryParams.organization,is_options: true, max: 100000, offset: 0}, {
          root: true
          }).then(()=>{
               this.accountOptionLoading = false;
          })
        } else {
          this.$store.dispatch('account/clear', {} , {root: true})
        }
      },
    filterMethod(query, item) {
          return item.name.indexOf(query) > -1 || item.organization.name.indexOf(query) > -1;
    },
    batchDelete() {
      this.$confirm('此操作將永久刪除該授權, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('audioAccount/batchDelete', {ids: this.multipleSelection.map(i=>{
              return {audio_id: i.audio_id, account_id:i.account_id}
          })} , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    submitBatch() {
        this.loading = true;
        this.$store.dispatch('audioAccount/batchUpdate', {ids: this.multipleSelection.map(i=>{
              return {audio_id: i.audio_id, account_id:i.account_id}
          }), expiration_date: this.batchForm.expiration_date} , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '更新!'
          });
          this.loading = false;
        this.dialogBatchEditFormVisible =false;
          })
        
    },
  
    clearForm() {
      if(this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      
      this.form.id=null;
      this.form.audio_no='';
      this.form.title=''
      this.form.tags=[];
      this.form.start_date= null;
      this.form.end_date= null;
      this.form.description ='';
      this.form.category = []
      this.form.fileList =[]
      this.form.audio_file_info='';
      if(this.$refs['upload']) {
        this.$refs['upload'].clearFiles();
      }
    },
    batchEditAudio() {
      if(this.$refs['batchForm']) {
        this.$refs['batchForm'].resetFields();
      }
      this.dialogBatchEditFormVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order,this.queryParams);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order,this.queryParams);

      },
    query(max,offset,sort ,order, params = {}) {
      this.$store.dispatch('audioAccount/list', {max: max, offset: offset, sort, order, ...params}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    categoryFormatter(row, column, cellValue) {
      if(row.audio.ancestors){
        return row.audio.ancestors.map(a=>a.name).join(' / ');
      } else {
        if(row.audio.category) {
          return row.audio.category.name;
        }
        
      }
      return '';
    },
    fileSizeFormatter(row, column, cellValue){
      if(cellValue) {
        return `${(cellValue/1024.0/1024.0).toFixed(2)} MB`
      } else {
        return '0';
      }
    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }

    },
    deleteItem(audio_id,account_id) {
      this.$confirm('此操作將永久刪除該授權, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('audioAccount/remove', {audio_id,account_id} , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order,this.queryParams)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('audioAccount/SET_SORT','account_id',{root: true})
        this.$store.commit('audioAccount/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('audioAccount/SET_SORT',args.prop,{root: true})
        this.$store.commit('audioAccount/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order,this.queryParams)
    }
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表]
          audios: 'audio/audios',
          accounts: 'account/accounts',
          audioAccounts: 'audioAccount/audioAccounts',
          total:'audioAccount/total',
          max:'audioAccount/max',
          offset:'audioAccount/offset',
          getAudioAccountById: 'audioAccount/getAudioAccountById',
          sort: 'audioAccount/sort',
          order: 'audioAccount/order',
          organizations: 'organization/organizations',
      }),
  },
}
</script>

<style scoped>
  .tag_group .el-tag+span {
    margin-left: 10px;
  }
  .el-transfer >>> .el-transfer-panel, .el-cascader {
    width: 350px;
  }
  .el-date-editor.el-input{
    width: 150px;
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
</style>
